
import React, {useState} from "react"
import Layout from "../../../components/layout"

import WebappWidgetTable from "../../../components/webapps/widget/table";

const formatTools = require("../../../../lib/formatTools");

const mainEntity = "onsbooking";

const validsellerlist = [
	{"value":2, "display":"Onprem Concepts"},
	{"value":3, "display":"Busy Tea Shop"},
	{"value":4, "display":"Barangay Burger Express"},
	{"value":5, "display":"Pizza Rush"},
	{"value":6, "display":"Negosyo Prime"},
];


const onsbookingstatuslist = [
	{"value":1,"display":"Ordered"},
	{"value":4,"display":"Allocated/Scheduled"},
	{"value":9,"display":"For scheduling (courier)"},
	{"value":10,"display":"For coordination (e.g. bus shipping)"},
	{"value":6,"display":"For re-scheduling"},
	{"value":7,"display":"Accepted/For dispatching"},
	{"value":8,"display":"Packed"},
	{"value":2,"display":"Dispatched"},
	{"value":3,"display":"Completed"},
	{"value":5,"display":"Cancelled"},
	{"value":12,"display":"Reschedule Pick up/Delivery"}
];

const mainFields = [
	{"label":"Seller", "dbfield": "onsbranch_id", "type": "combo", "filtertype": "combo", "options": validsellerlist},
	{"label":"Order Date", "dbfield": "onsbooking_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Status", "dbfield": "onsbookingstatus_id", "type": "combo", "filtertype": "combo", "options": onsbookingstatuslist},
	{"label":"Completion/Update", "dbfield": "onsbooking_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Ordered Amount", "dbfield": "onsbooking_totalamount", "type": "currency", "filtertype": "currency"},
	{"label":"Delivered Amount", "dbfield": "onsbooking_deliveramount", "type": "currency", "filtertype": "currency"},
	{"label":"Commission %", "formula":"100.0*onsbooking_commission/(onsbooking_deliveramount+0.0001)", "dbfield": "computedrate", "type": "currency", "filtertype": "currency"},
	{"label":"Commission Amount", "dbfield": "onsbooking_commission", "type": "currency", "filtertype": "currency"},

];

const mainFilterFields = [
	{"label":"Seller", "dbfield": "onsbranch_id", "type": "combo", "filtertype": "combo", "options": validsellerlist},
	{"label":"Order Date", "dbfield": "onsbooking_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Status", "dbfield": "onsbookingstatus_id", "type": "combo", "filtertype": "combo", "options": onsbookingstatuslist},
	{"label":"Completion/Update", "dbfield": "onsbooking_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},

];

const ReportBillingLineItemPage = ({location}) => {
	const [token, setToken] = useState("");

	const defaultstartdate = formatTools.getDateStr(8-24*30); // ~1months ago


	const mainQueryparams = {
		tablelist: ["ONSORDERFORM"],
		//joinlist: [],
		condlist: [
			"ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id",
			"ONSBOOKING.onscustomer_id not in (763, 1000, 1246, 1025, 1778 , 4341, 9, 1341, 2660, 3117, 4153, 4154, 4219, 4220, 4622, 1797, 1798, 1803, 1892, 1899, 1885, 1886, 4, 760, 772, 890)"
		],
	}

	const tmpsearchstate = {
		"filter":[
			{
				"display":"Past 30 days",
				//"display":"Order date after '"+formatTools.datestr(defaultstartdate)+"'",
				"field":"onsbooking_datetime", "operation":" >= ","value":defaultstartdate},
			//{"display":"Order date before '7/1/2024'","field":"onsorderform_datetime", "operation":" < ","value":"2024-07-01"}
		],
		"orderbyfield": "onsbooking_datetime",
		"orderbymode": "desc"

	};

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				<WebappWidgetTable
						title={"Billing Report"}
						parentlink={{"title":"Reports", "url":"/reports"}}
						token={token}
						entity={mainEntity}
						fieldList={mainFields}
						filterfieldList={mainFilterFields}
						userparam={mainQueryparams}
						initsearchstate={tmpsearchstate}
						showControls={true}
						showDownload={true}
					/>
		</Layout>
}


export default ReportBillingLineItemPage;
